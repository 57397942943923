<template>
  <div>
    <v-card>
      <v-card-title class="text-h5"> <h2>Members Search</h2> </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Use this interface to view a member by membership id.</h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="ProgessStart == 1">
          <b-spinner
            variant="primary"
            type="grow"
            label="We are fetching details, please wait..."
          ></b-spinner>
        </div>

        <v-container>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"> Cancel </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"> OK </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <br />

          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <br />
              <v-btn color="blue" class="mr-4" @click="refreshPageData">
                Refresh
              </v-btn>
              <p></p>
            </v-col>
          </v-row> -->

          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              id="createForm"
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="MembershipId"
                    :rules="MembershipIdRules"
                    label="MembershipId"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="searchForm"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container class="py-0" v-if="Members.length > 0">
            <v-row wrap v-if="DeleteFlag == true">
              <v-col align="center" cols="12" md="12">
                <!-- <v-btn color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
                <p></p>
                <json-excel
                  v-if="DownloadFlag == 1"
                  :data="Members"
                  :fields="excel_fields"
                  worksheet="My Worksheet"
                  :name="ExcelFileName"
                >
                  <v-btn color="info" class="mr-4"> Download </v-btn>
                </json-excel>
              </v-col>
              <p></p>
            </v-row>
            <br />
            <h3>Members List</h3>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search1"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  :single-select="false"
                  v-model="selected1"
                  :headers="tableColumns1"
                  :items="Members"
                  :items-per-page="50"
                  :search="search1"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50],
                  }"
                  item-key="MemberId"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>

          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: 1,
      InstructionFlag: 0,

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      YearHalfRules: [(v) => !!v || "Year Half is required"],
      YearHalf: "",
      YearHalfOptions: [
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      LomCodeRules: [(v) => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      MemberNameRules: [(v) => !!v || "Name is required"],
      MemberEmailRules: [],
      MemberMobileNoRules: [],
      MemberAddressRules: [],
      MemberCityRules: [],

      ExcelFileName: "Dues_Paid_Lists.xls",
      excel_fields: {
        "SR. NO.": "sno",
        ZONE: "ZoneName",
        "MEMBER ID": "MembershipId",
        NAME: "MemberName",
        "LOM NAME": "LomName",
        ADDRESS: "AddressTxt1",
        CITY: "CityName",
        DISTRICT: "DistrictName",
        STATE: "StateName",
        PIN: "Pincode",
        "Mobile No": "MobileNo",
        "Email Id": "EmailId",
      },

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",

      ResultFlag: 0,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search1: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordToEdit: "",

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "", value: "actions", sortable: false, groupable: false },
        { text: "Status", value: "ActiveStatusTxt", groupable: false },
        { text: "Register No", value: "AwardsRegisterNo" },
        { text: "Zone Name", value: "ZoneName" },
        { text: "LOM", value: "LomName" },
        { text: "Award Type", value: "AwardsTypeTitle" },
        { text: "Token", value: "AwardsToken", groupable: false },
        {
          text: "President Details",
          value: "LomPresidentName",
          groupable: false,
        },
        {
          text: "100% Efficiency",
          value: "FullEfficiencyPoints",
          groupable: false,
        },
        { text: "Score", value: "TotalAssessmentScore", groupable: false },
      ],
      rows: [],
      Members: [],
      TotalMembers: 0,

      tableColumns1: [],
      tableColumns2: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key]) return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key]) return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    selected1: function () {
      console.log("watch selected1");
      this.calculateTotalMembers();
    },
    selected2: function () {
      console.log("watch selected2");
      this.calculateTotalMembers();
    },
    NewMembers: function () {
      console.log("watch NewMembers");
      this.calculateTotalMembers();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    calculateTotalMembers() {
      console.log("calculateTotalMembers called");
      var c1 = this.selected1.length;
      var c2 = this.selected2.length;
      var c3 = this.NewMembers.length;
      console.log("c1=" + c1 + ", c2=" + c2 + ", c3=" + c3);
      var TotalMembers = parseInt(c1) + parseInt(c2) + parseInt(c3);
      console.log("TotalMembers=" + TotalMembers);
      this.TotalMembers = TotalMembers;
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordToEdit = 1;
        this.previewRecordPrompt = true;
        this.selected = tr.AwardsId;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var AwardsId = tr.AwardsId;
        console.log("AwardsId=" + AwardsId);
        var params = {};
        var url = "/national/awards/lom-update";
        this.$session.set("EditAwardsId", AwardsId);
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);
      if (tableId == 1) {
        var n1 = this.AllDesignation.length;
        console.log("n1=" + n1);
        if (n1 < 5) {
          this.AllDesignation.push({
            year: "",
            level: "",
            position: "",
          });
        }
      }

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        if (n1 < 30) {
          this.NewMembers.push({
            MemberName: "",
            MemberEmail: "",
            MemberMobileNo: "",
            MemberAddress: "",
            MemberCity: "",
          });
        }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getJciYearCodeOptions();
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFormFieldOptions() {
      console.log("getFormFieldOptions called");
      // this.getContributionTypeIdOptions();
      this.getZoneCodeOptions();
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    searchForm() {
      console.log("searchForm called");

      this.resetMessageTxt();
      this.selected1 = [];
      this.Members = [];

      var MembershipId = this.MembershipId;
      console.log("MembershipId=" + MembershipId);

      if (MembershipId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: 3,
          additional: {
            MembershipId: MembershipId,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = output;
              thisIns.Members = records;
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = output;
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "Membersip Id should not be empty.";
      }
    },
    deleteRecords() {
      console.log("deleteRecords called");

      this.resetMessageTxt();

      var LomCode = this.LomCode;
      var YearCode = this.JciYearCode;
      var YearHalf = this.YearHalf;
      console.log(
        "LomCode=" + LomCode + ", YearCode=" + YearCode + ", YearHalf=" + YearHalf
      );

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      if (LomCode != "" && YearCode != "" && YearHalf != "" && n1 > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-dues/destroy";
        var upload = {
          LomCode: LomCode,
          YearCode: YearCode,
          YearHalf: YearHalf,
          Members: selected,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = output;
              thisIns.Members = [];
              thisIns.getDuesPaidMembers();
              thisIns.refreshPageData();
              thisIns.alert = {
                flag: true,
                color: "info",
                message: output,
              };
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = output;

              thisIns.alert = {
                flag: true,
                color: "error",
                message: output,
              };
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    addItem() {
      console.log("addItem called");
      this.dialog = true;
    },
    initialize() {
      this.rows = [];
    },

    editItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialog = true;
    },

    deleteItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.rows.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.rows[this.editedIndex], this.editedItem);
      } else {
        this.rows.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    if (RoleId != "") {
      this.refreshPageData();
      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        this.DeleteFlag = RoleId == 1 || RoleId == 2 ? true : false;
        this.tableColumns1.push(
          { text: "#", value: "sno" },
          { text: "Zone", value: "CurrentZoneName" },
          { text: "LOM", value: "LomName" },
          { text: "Member Name", value: "MemberName" },
          { text: "Mobile No.", value: "MobileNo" },
          { text: "Email", value: "EmailId" },
          { text: "Address", value: "AddressTxt" },
          { text: "Area", value: "Area" },
          { text: "City", value: "CityName" },
          { text: "State", value: "StateName" },
          { text: "actions", value: "actions" }
        );
      } else {
        this.tableColumns1 = [];
      }
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>

<style lang="scss"></style>
